.App {
  /* text-align: center; */
}

.App .ant-list-vertical .ant-list-item-meta-title
{
    margin-bottom: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
font-family: "michelinIcon";
/* src: url("https://guide.michelin.com/assets/web/fonts/annuel/Annuels-f9aa99019cb3d27806d129e37c3d657f.woff") format('woff'); */
src: url("./fonts/Annuels-f9aa99019cb3d27806d129e37c3d657f.woff") format('woff');
}
.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.calendar-date-box {
    border-radius: 50%;
    border-style: solid;
    border-width: 1px ;
    height: 100%;
    margin-left: 20.9%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-date-available {
    border-color:#1890ff;
    font-weight: bold;
    color: #1890ff;
}

.calendar-date-available-today {
    background-color:#1890ff;
    font-weight: bold;
    color: white;
}

.calendar-date-not-available {
  text-decoration: line-through;
    color: grey;
    background-color: white;
}


.carousel_container {
  display: flex;
  justify-content: center;
  text-align: center;
}

